import * as React from 'react';

import { CheckIcon } from 'components/icons';
import { Container } from 'components/shared/container';

import BalanceIconSvgUrl from './images/balance.url.svg';
import CampaignManagementIconSvgUrl from './images/campaign-management.url.svg';
import ComplianceIconSvgUrl from './images/compliance.url.svg';
import CustomerSupportIconSvgUrl from './images/customer-support.url.svg';
import DeliveryIconSvgUrl from './images/delivery.url.svg';
import FlexiblePaymentIconSvgUrl from './images/flexible-payment.url.svg';
import IntegrationsIconSvgUrl from './images/integrations.url.svg';
import OrderingIconSvgUrl from './images/ordering.url.svg';
import PromosIconSvgUrl from './images/promos.url.svg';
import TrackingIconSvgUrl from './images/tracking.url.svg';
import UsersIconSvgUrl from './images/users.url.svg';
import WalletIconSvgUrl from './images/wallet.url.svg';
import {
  Wrapper,
  StyledTitle,
  Grid,
  GridItem,
  Item,
  ItemTitle,
  Features,
  FeaturesItem,
  FeaturesText,
  FeaturesIcon,
  Icon,
} from './tools.styles';

const iconCollection = {
  ordering: OrderingIconSvgUrl,
  delivery: DeliveryIconSvgUrl,
  wallet: WalletIconSvgUrl,
  integrations: IntegrationsIconSvgUrl,
  tracking: TrackingIconSvgUrl,
  customerSupport: CustomerSupportIconSvgUrl,
  balance: BalanceIconSvgUrl,
  flexiblePayment: FlexiblePaymentIconSvgUrl,
  promos: PromosIconSvgUrl,
  campaignManagement: CampaignManagementIconSvgUrl,
  compliance: ComplianceIconSvgUrl,
  users: UsersIconSvgUrl,
};

export const Tools = ({ title, items }) => (
  <Wrapper>
    <Container>
      <StyledTitle as="h2" size="sm">
        {title}
      </StyledTitle>

      <Grid
        columns={3}
        gap={[24, 56]}
        media={{
          md: { columns: 2 },
          xs: { columns: 1 },
        }}
      >
        {items.map(({ iconName, title, features }, index) => (
          <GridItem key={index}>
            <Item>
              <Icon
                src={iconCollection[iconName]}
                loading="lazy"
                alt=""
                height={80}
                width={80}
                arria-hidden
              />
              <ItemTitle as="h4" size="xs">
                {title}
              </ItemTitle>

              <Features>
                {features.map(({ text }, index) => (
                  <FeaturesItem key={index}>
                    <FeaturesIcon>
                      <CheckIcon />
                    </FeaturesIcon>
                    <FeaturesText
                      dangerouslySetInnerHTML={{
                        __html: text,
                      }}
                    />
                  </FeaturesItem>
                ))}
              </Features>
            </Item>
          </GridItem>
        ))}
      </Grid>
    </Container>
  </Wrapper>
);
