import * as React from 'react';

import { Container } from 'components/shared/container';

import { Wrapper, StyledTitle, Subtitle, Grid, GridItem, Logo } from './brands.styles';
import AdidasLogoSvgUrl from './images/adidas.url.svg';
import AirbnbLogoSvgUrl from './images/airbnb.url.svg';
import AmazonLogoSvgUrl from './images/amazon.url.svg';
import AppleLogoSvgUrl from './images/apple.url.svg';
import HMLogoSvgUrl from './images/h&m.url.svg';
import LogoSvgUrl from './images/logo.url.svg';
import NikeLogoSvgUrl from './images/nike.url.svg';
import SpotifyLogoSvgUrl from './images/spotify.url.svg';
import StarbucksLogoSvgUrl from './images/starbucks.url.svg';
import TescoLogoSvgUrl from './images/tesco.url.svg';
import UberLogoSvgUrl from './images/uber.url.svg';

const iconCollection = [
  AirbnbLogoSvgUrl,
  StarbucksLogoSvgUrl,
  AdidasLogoSvgUrl,
  AmazonLogoSvgUrl,
  NikeLogoSvgUrl,
  LogoSvgUrl,
  TescoLogoSvgUrl,
  SpotifyLogoSvgUrl,
  AppleLogoSvgUrl,
  UberLogoSvgUrl,
  HMLogoSvgUrl,
];

export const Brands = ({ title, subtitle }) => (
  <Wrapper>
    <Container>
      <StyledTitle as="h2" size="md">
        {title}
      </StyledTitle>
      <Subtitle>{subtitle}</Subtitle>

      <Grid
        columns={6}
        gapX={16}
        gapY={30}
        media={{
          lg: { columns: 5 },
          md: { columns: 4 },
          xs: { columns: 3 },
          xxs: { columns: 2, gapY: 15 },
        }}
      >
        {iconCollection.map((logo, index) => (
          <GridItem key={index}>
            <Logo src={logo} alt="" loading="lazy" width={137} height={137} aria-hidden />
          </GridItem>
        ))}
      </Grid>
    </Container>
  </Wrapper>
);
