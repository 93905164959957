import styled from 'styled-components';

import { fontSize, media, color } from 'components/_settings/variables';
import { Grid as SharedGrid, GridCell } from 'components/shared/grid';
import { Title } from 'components/shared/title';

export const StyledTitle = styled(Title)`
  font-weight: 400;
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 70px;

  ${media.sm} {
    margin-bottom: 30px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  text-align: center;
  margin-bottom: 70px;

  ${media.sm} {
    margin-bottom: 40px;
  }
`;

export const Grid = styled(SharedGrid)`
  max-width: 974px;
  margin: 0 auto;
`;

export const GridItem = styled(GridCell)``;

export const Item = styled.div`
  text-align: left;

  ${media.xs} {
    text-align: center;
  }
`;

export const ItemTitle = styled(Title)`
  font-weight: 400;
  margin-bottom: 16px;
`;

export const Icon = styled.img`
  margin-bottom: 24px;

  ${media.sm} {
    margin-bottom: 16px;
  }

  ${media.xs} {
    margin-bottom: 0;
  }
`;

export const FeaturesIcon = styled.div`
  display: flex;
  margin-right: 15px;

  svg {
    path {
      stroke: ${color.primary};
    }
  }

  ${media.xs} {
    display: none;
  }
`;

export const FeaturesText = styled.p`
  font-size: ${fontSize.sm};
  color: #6b7696;
  font-weight: 400;
  line-height: 1.7;
  margin-top: 0;
  margin-bottom: 0;

  ${media.sm} {
    font-size: ${fontSize.xs};
  }

  a {
    color: ${color.primary};
    text-decoration: none;
  }
`;

export const FeaturesItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  ${media.xs} {
    justify-content: center;
  }
`;

export const Features = styled.ul`
  display: inline-block;
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-left: 0;
  margin-bottom: 0;
`;
