import styled from 'styled-components';

import { fontSize, media, color } from 'components/_settings/variables';
import { Button } from 'components/shared/button';
import { Grid as SharedGrid, GridCell } from 'components/shared/grid';
import { Title } from 'components/shared/title';

export const StyledTitle = styled(Title)`
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
`;

export const Wrapper = styled.div`
  position: relative;
  text-align: center;
  margin-bottom: 100px;

  ${media.sm} {
    margin-bottom: 70px;
  }
`;

export const Grid = styled(SharedGrid)``;

export const GridItem = styled(GridCell)`
  display: flex;
  justify-content: center;

  &:first-child {
    border-right: 1px solid #d9dfe4;

    ${media.sm} {
      border-right: none;
      border-bottom: 1px solid #d9dfe4;
    }
  }
`;

export const Content = styled.div`
  max-width: 280px;
  padding: 50px 0 55px;

  ${media.xs} {
    padding: 40px 0 45px;
  }
`;

export const Icon = styled.img`
  margin-bottom: 24px;

  ${media.sm} {
    margin-bottom: 16px;
  }

  ${media.xs} {
    margin-bottom: 0;
  }
`;

export const Box = styled.div`
  position: relative;
  background-color: ${color.text.tertiary};
  border-radius: 8px;
  box-shadow: 2px 2px 12px rgba(26, 26, 67, 0.05), 14px 36px 120px -15px rgba(50, 50, 93, 0.1);

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: calc(100% + 50px);
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(126.77deg, #ebeafd 5.86%, #cbeaec 59.84%, #ffd9ec 112.49%);
    border-radius: 8px;
    z-index: -1;
  }
`;

export const StyledButton = styled(Button)`
  justify-content: center;
  width: 100%;

  span {
    width: 100%;
  }
`;

export const Description = styled.p`
  display: block;
  font-size: ${fontSize.xs};
  text-align: center;
  color: #6b7696;
  margin-top: 0;
  margin-bottom: 24px;
`;
