import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { Container as SharedContainer } from 'components/shared/container';
import { Grid as SharedGrid, GridCell } from 'components/shared/grid';
import { Title } from 'components/shared/title';

export const Grid = styled(SharedGrid)`
  & > div {
    justify-content: center;
  }
`;

export const GridItem = styled(GridCell)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoWrapper = styled.div`
  ${media.sm} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .gatsby-image-wrapper {
    ${media.md} {
      img {
        object-fit: contain !important;
      }
    }
  }
`;

export const Wrapper = styled.div`
  padding-bottom: 85px;
`;

export const Container = styled(SharedContainer)``;

export const ContentWrapper = styled.div``;

export const StyledTitle = styled(Title)`
  font-weight: normal;
  text-align: center;
  margin-top: 0;
  margin-bottom: 30px;

  ${media.sm} {
    margin-bottom: 20px;
  }
`;
