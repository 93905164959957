import styled from 'styled-components';

import { fontSize, media } from 'components/_settings/variables';
import { Grid as SharedGrid, GridCell } from 'components/shared/grid';
import { Title } from 'components/shared/title';

export const StyledTitle = styled(Title)`
  font-weight: 400;
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 70px;

  ${media.sm} {
    margin-bottom: 30px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  text-align: center;
  margin-bottom: 70px;

  ${media.sm} {
    margin-bottom: 40px;
  }
`;

export const Subtitle = styled.h2`
  font-size: ${fontSize.lg};
  font-weight: 400;
  line-height: 1.3;
  max-width: 465px;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;

  ${media.sm} {
    font-size: ${fontSize.md};
  }

  ${media.xs} {
    font-size: ${fontSize.sm};
  }
`;

export const Grid = styled(SharedGrid)`
  max-width: 972px;
  margin: 0 auto;

  & > div {
    justify-content: center;
  }
`;

export const GridItem = styled(GridCell)`
  img {
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0px 13.1602px 17.5469px -10.4185px rgba(44, 41, 93, 0.500085);

    ${media.sm} {
      height: 100px;
      width: 100px;
    }
  }
`;

export const Logo = styled.img``;
