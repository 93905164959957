import styled, { keyframes } from 'styled-components';

import { fontSize, media, color } from 'components/_settings/variables';
import { Button } from 'components/shared/button';
import { Container } from 'components/shared/container';
import { Grid as SharedGrid, GridCell } from 'components/shared/grid';
import { Title } from 'components/shared/title';

const wiggle = keyframes`
0% {
  transform: translateZ(-10px);
}

50% {
  transform: translate3d(0, 10px, 0);
}

to {
  transform: translateZ(-10px);
}
`;

const wiggleTwo = keyframes`
0% {
  transform: translateZ(0);
}

50% {
  transform: translate3d(0, -10px, 0);
}

to {
  transform: translateZ(0);
}
`;

export const Inner = styled(Container)`
  position: relative;
`;

export const StyledTitle = styled(Title)`
  font-weight: 400;
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;

  ${media.sm} {
    margin-bottom: 15px;
  }
`;

export const Description = styled.p`
  font-size: ${fontSize.md};
  line-height: 1.5;
  text-align: center;
  color: #6b7696;
  max-width: 670px;
  margint-top: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 70px;

  ${media.sm} {
    font-size: ${fontSize.sm};
    margin-bottom: 40px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  text-align: center;
  padding-top: 165px;
  margin-bottom: 70px;

  ${media.sm} {
    padding-top: 100px;
    margin-bottom: 40px;
  }
`;

export const Box = styled.div`
  max-width: 584px;
  background-color: ${color.text.tertiary};
  border-radius: 8px;
  box-shadow: 2px 2px 12px rgba(26, 26, 67, 0.05), 14px 36px 120px -15px rgba(50, 50, 93, 0.1);
  padding: 30px 40px 50px;
  margin-right: auto;
  margin-left: auto;

  ${media.xs} {
    padding: 25px 30px 40px;
  }
`;

export const BoxTitle = styled.h2`
  font-size: 36px;
  font-weight: 400;
  line-height: 1.3;
  max-width: 465px;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;

  ${media.sm} {
    font-size: 28px;
    margin-bottom: 30px;
  }

  ${media.xs} {
    font-size: ${fontSize.lg};
  }
`;

export const ItemIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  background-color: #eee2ff;
  border-radius: 50%;
  margin-right: 16px;

  svg {
    path {
      stroke: #bda3de;
    }
  }
`;

export const ItemText = styled.p`
  font-size: ${fontSize.sm};
  font-weight: 400;
  text-align: left;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 0;

  ${media.xs} {
    font-size: ${fontSize.xs};
  }
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Features = styled.ul`
  display: block;
  list-style-type: none;
  padding-left: 0;
  max-width: 375px;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
`;

export const ButtonLabel = styled.span`
  display: block;
  font-size: ${fontSize.sm};
  text-align: center;
  color: #6b7696;
  margin-bottom: 16px;
`;

export const StyledButton = styled(Button)`
  justify-content: center;
  width: 100%;

  span {
    width: 100%;
  }
`;

export const Grid = styled(SharedGrid)`
  border-top: 1px solid #e3e5eb;
  padding-top: 25px;
`;

export const GridItem = styled(GridCell)``;

export const TriangleLeft = styled.img`
  position: absolute;
  top: -95px;
  left: -301px;
  z-index: -2;

  ${media.md} {
    left: -441px;
  }

  ${media.sm} {
    display: none;
  }
`;

export const TriangleRight = styled.img`
  position: absolute;
  top: -529px;
  right: -441px;
  z-index: -2;

  ${media.md} {
    right: -581px;
  }

  ${media.sm} {
    right: -631px;
  }
`;

export const LogosWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;

  ${media.sm} {
    display: none;
  }

  img {
    border-radius: 50%;
    box-shadow: 0px 19.7403px 26.3203px -15.9019px rgba(44, 41, 93, 0.500085);
  }
`;

export const NikeLogo = styled.img`
  position: absolute;
  top: 23%;
  left: 12.6%;
  animation: ${wiggleTwo} 3s infinite cubic-bezier(1, 1, 0, 0);

  ${media.lg} {
    left: 2.6%;
  }

  ${media.md} {
    left: -3%;
  }
`;

export const TescoLogo = styled.img`
  position: absolute;
  top: 45.5%;
  left: 9.7%;
  animation: ${wiggle} 3s infinite cubic-bezier(1, 1, 0, 0);

  ${media.lg} {
    left: 1%;
  }

  ${media.md} {
    left: -8%;
  }
`;

export const UberLogo = styled.img`
  position: absolute;
  top: 80.7%;
  left: 15.4%;
  animation: ${wiggleTwo} 3s infinite cubic-bezier(1, 1, 0, 0);

  ${media.lg} {
    left: 5.4%;
  }

  ${media.md} {
    left: -4.4%;
  }
`;

export const AppleLogo = styled.img`
  position: absolute;
  right: 17%;
  top: 22.5%;
  animation: ${wiggle} 3s infinite cubic-bezier(1, 1, 0, 0);

  ${media.lg} {
    right: 12.5%;
  }

  ${media.md} {
    right: -2.5%;
  }
`;

export const SpotifyLogo = styled.img`
  position: absolute;
  top: 49.5%;
  right: 13.2%;
  animation: ${wiggleTwo} 3s infinite cubic-bezier(1, 1, 0, 0);

  ${media.lg} {
    right: 3.2%;
  }

  ${media.md} {
    right: -8%;
  }
`;
