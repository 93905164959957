import { Link } from 'gatsby';
import * as React from 'react';

import { Container } from 'components/shared/container';

import {
  Wrapper,
  Content,
  StyledTitle,
  Description,
  StyledButton,
  Box,
  Grid,
  GridItem,
  Icon,
} from './get-started.styles';
import IconLeftSvgUrl from './images/icon-left.url.svg';
import IconRightSvgUrl from './images/icon-right.url.svg';

export const GetStarted = ({
  titleLeft,
  descriptionLeft,
  buttonTextLeft,
  buttonUrlLeft,
  titleRight,
  descriptionRight,
  buttonTextRight,
  buttonUrlRight,
}) => (
  <Wrapper>
    <Container>
      <Box>
        <Grid
          columns={2}
          gap={0}
          media={{
            sm: { columns: 1 },
          }}
        >
          <GridItem>
            <Content>
              <Icon src={IconLeftSvgUrl} loading="lazy" height={64} width={64} alt="" aria-hidden />
              <StyledTitle as="h3" size="sm">
                {titleLeft}
              </StyledTitle>
              <Description>{descriptionLeft}</Description>

              <StyledButton component={Link} to={buttonUrlLeft} arrow={false} theme="bordered">
                {buttonTextLeft}
              </StyledButton>
            </Content>
          </GridItem>
          <GridItem>
            <Content>
              <Icon
                src={IconRightSvgUrl}
                loading="lazy"
                height={64}
                width={64}
                alt=""
                aria-hidden
              />
              <StyledTitle as="h3" size="sm">
                {titleRight}
              </StyledTitle>
              <Description>{descriptionRight}</Description>

              <StyledButton component={Link} to={buttonUrlRight} arrow={false} theme="bordered">
                {buttonTextRight}
              </StyledButton>
            </Content>
          </GridItem>
        </Grid>
      </Box>
    </Container>
  </Wrapper>
);
