import { Link } from 'gatsby';
import * as React from 'react';

import { CheckIcon } from 'components/icons';

import {
  Wrapper,
  Inner,
  StyledTitle,
  Description,
  Box,
  BoxTitle,
  Features,
  Item,
  ItemIcon,
  ItemText,
  ButtonLabel,
  StyledButton,
  Grid,
  GridItem,
  TriangleLeft,
  TriangleRight,
  LogosWrapper,
  NikeLogo,
  TescoLogo,
  UberLogo,
  AppleLogo,
  SpotifyLogo,
} from './hero.styles';
import AppleLogoSvgUrl from './images/apple.url.svg';
import NikeLogoSvgUrl from './images/nike.url.svg';
import SpotifyLogoSvgUrl from './images/spotify.url.svg';
import TescoLogoSvgUrl from './images/tesco.url.svg';
import TriangleLeftSvgUrl from './images/triangle-left.url.svg';
import TriangleRightSvgUrl from './images/triangle-right.url.svg';
import UberLogoSvgUrl from './images/uber.url.svg';

export const Hero = ({
  title,
  description,
  payment: {
    title: boxTitle,
    features,
    button1AboveText,
    button1Text,
    button1Url,
    button2AboveText,
    button2Text,
    button2Url,
  },
}) => (
  <Wrapper>
    <Inner>
      <StyledTitle size="xl">{title}</StyledTitle>
      <Description dangerouslySetInnerHTML={{ __html: description }} />

      <Box>
        <BoxTitle>{boxTitle}</BoxTitle>
        <Features>
          {features.map(({ text }, index) => (
            <Item key={index}>
              <ItemIcon>
                <CheckIcon />
              </ItemIcon>
              <ItemText>{text}</ItemText>
            </Item>
          ))}
        </Features>
        <Grid
          columns={2}
          gap={24}
          media={{
            sm: { columns: 1 },
          }}
        >
          <GridItem>
            <ButtonLabel>{button1AboveText}</ButtonLabel>
            <StyledButton component={Link} to={button1Url} arrow={false}>
              {button1Text}
            </StyledButton>
          </GridItem>
          <GridItem>
            <ButtonLabel>{button2AboveText}</ButtonLabel>
            <StyledButton component={Link} to={button2Url} theme="bordered" arrow={false}>
              {button2Text}
            </StyledButton>
          </GridItem>
        </Grid>
      </Box>

      <LogosWrapper>
        <NikeLogo src={NikeLogoSvgUrl} height={74} width={74} loading="eager" alt="" aria-hidden />
        <TescoLogo
          src={TescoLogoSvgUrl}
          height={116}
          width={116}
          loading="eager"
          alt=""
          aria-hidden
        />
        <UberLogo src={UberLogoSvgUrl} height={90} width={90} loading="eager" alt="" aria-hidden />
        <AppleLogo
          src={AppleLogoSvgUrl}
          height={63}
          width={63}
          loading="eager"
          alt=""
          aria-hidden
        />
        <SpotifyLogo
          src={SpotifyLogoSvgUrl}
          height={99}
          width={99}
          loading="eager"
          alt=""
          aria-hidden
        />
      </LogosWrapper>
    </Inner>

    <TriangleLeft src={TriangleLeftSvgUrl} loading="eager" alt="" aria-hidden />
    <TriangleRight src={TriangleRightSvgUrl} loading="eager" alt="" aria-hidden />
  </Wrapper>
);
