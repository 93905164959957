import GatsbyImage from 'gatsby-image';
import * as React from 'react';

import { getFluidImage } from 'utils';

import {
  Wrapper,
  Container,
  StyledTitle,
  Grid,
  GridItem,
  LogoWrapper,
  ContentWrapper,
} from './pricing-logos.styles';

export const Logos = ({ title, images }) => (
  <Wrapper>
    <Container>
      <StyledTitle size="md" as="h2">
        {title}
      </StyledTitle>

      <ContentWrapper>
        <Grid
          columns={6}
          gap={[25, 0]}
          media={{
            md: { columns: 4 },
            sm: { columns: 3 },
            xs: { columns: 2 },
          }}
        >
          {images.map(({ altText, localFile }, index) => {
            const fluid = getFluidImage(localFile);
            return (
              <GridItem key={index}>
                <LogoWrapper>
                  <GatsbyImage
                    style={{
                      width: fluid.presentationWidth / 2,
                      height: fluid.presentationHeight / 2,
                    }}
                    fluid={fluid}
                    alt={altText}
                  />
                </LogoWrapper>
              </GridItem>
            );
          })}
        </Grid>
      </ContentWrapper>
    </Container>
  </Wrapper>
);
