import { graphql } from 'gatsby';
import * as React from 'react';

import Layout from 'components/_settings/layout';
import { Footer } from 'components/blocks/footer';
import { Header } from 'components/blocks/header';
import { Brands } from 'components/pages/pricing/brands/';
import { GetStarted } from 'components/pages/pricing/get-started/';
import { Hero } from 'components/pages/pricing/hero/';
import { Logos } from 'components/pages/pricing/logos';
import { Tools } from 'components/pages/pricing/tools/';

import { buildPageMetadata } from '../utils/data-builders';

const Pricing = ({ data: { wpPage } }) => {
  const {
    acf: { pricingHero, pricingBrands, pricingTools, pricingGetStarted, logos },
  } = wpPage;

  return (
    <Layout pageMetadata={buildPageMetadata(wpPage)}>
      <Header />
      <Hero {...pricingHero} />
      <Brands {...pricingBrands} />
      <Tools {...pricingTools} />
      <GetStarted {...pricingGetStarted} />
      <Logos {...logos} />
      <Footer theme="light" />
    </Layout>
  );
};

export default Pricing;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      ...wordpressPagePageMetadata
      title
      acf {
        pricingHero {
          title
          description
          payment {
            title
            features {
              text
            }
            button1AboveText
            button1Text
            button1Url
            button2AboveText
            button2Text
            button2Url
          }
        }
        pricingBrands {
          title
          subtitle
        }
        pricingTools {
          title
          items {
            iconName
            title
            features {
              text
            }
          }
        }
        pricingGetStarted {
          titleLeft
          descriptionLeft
          buttonTextLeft
          buttonUrlLeft
          titleRight
          descriptionRight
          buttonTextRight
          buttonUrlRight
        }
        logos {
          title
          images {
            altText
            localFile {
              childImageSharp {
                fluid {
                  presentationWidth
                  presentationHeight
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
